import { gql } from '@apollo/client';

import ApolloService from '../service';

const getAllQuery = gql`
  query GetUsers($filters: UserAdminFilterInput) {
    usersAdmin(filters: $filters) {
      id
      email
      firstName
      lastName
      pushMarketing
      emailMarketing
      status
      lastLoginDate
      currentSubscriptionId
      currentSubscriptionPurchaseDate
      currentSubscriptionExpirationDate
      currentSubscriptionStatus
      currentSubscriptionPeriod
      firstSubscriptionPurchaseDate
      originalCompanyId
      originalCompanyName
      presentCompanyId
      presentCompanyName
      companies {
        name
      }
      subscription {
        id
        status
        type
        companyId
        productId
        productSku
        expirationDate
      }
    }
    userAdminTotals(filters: $filters) {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetUser($id: String!) {
    userAdmin(id: $id) {
      id
      email
      firstName
      lastName
      pushNotifications
      emailNotifications
      pushMarketing
      emailMarketing
      creationDate
      deactivationDate
      status
      subscription {
        id
        status
        type
        companyId
        productId
        productSku
        expirationDate
      }
      bookPackages {
        id
        name
      }
    }
  }
`;

const updateMutation = gql`
  mutation UpdateUser($id: String!, $input: UpdateUserInput!) {
    updateUser(id: $id, input: $input) {
      id
      email
      firstName
      lastName
      pushNotifications
      emailNotifications
      pushMarketing
      emailMarketing
      creationDate
      deactivationDate
      bookPackages {
        id
      }
      status
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id) {
      id
      email
    }
  }
`;

const exportMutation = gql`
  mutation ExportUsers($filters: UserAdminFilterInput) {
    exportUsersAdmin(filters: $filters) {
      token
      link
    }
  }
`;

export default class UserApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      exportMutation: exportMutation,
      exportKey: 'exportUsersAdmin',
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.usersAdmin,
        total: data.userAdminTotals,
      }),
    });
  }
}
